// Generated by CoffeeScript 2.7.0
/* eslint-disable */

(function () {
  var base;

  window.Lanalytics || (window.Lanalytics = {});

  (base = window.Lanalytics).Model || (base.Model = {});

  window.Lanalytics.Model.StmtVerb = class StmtVerb extends (
    window.Lanalytics.Model.StmtComponent
  ) {
    constructor(type) {
      super(type);
    }

    params() {
      return {
        type: this.type,
      };
    }
  };
}).call(this);
