// Generated by CoffeeScript 2.7.0
/* eslint-disable */

(function () {
  window.Lanalytics.Plugins.VisitedEventsPlugin = class VisitedEventsPlugin extends (
    Lanalytics.Plugin
  ) {
    // This is the interface method expected by Lanalytics
    static newInstance(lanalytics) {
      return new Lanalytics.Plugins.VisitedEventsPlugin(lanalytics);
    }

    constructor(lanalytics) {
      super(lanalytics);
      this._init();
    }

    _init() {
      var inContext, path, resource, resourceType, verb;
      // lanalytics: tracking user flow
      if (gon.user_id == null) {
        return;
      }
      path = window.location.pathname;
      verb = void 0;
      resource = void 0;
      resourceType = void 0;
      inContext = {};
      if (/\/question/.test(path)) {
        verb = 'visited_question';
        resource = gon.question_id;
        resourceType = 'question';
        inContext['course_id'] = gon.course_id;
      }
      if (!gon.in_app) {
        if (
          /\/items/.test(path) &&
          !/\/items\/new/.test(path) &&
          !/\/items\/.*\/edit/.test(path) &&
          !/\/items\/.*\/stats/.test(path)
        ) {
          verb = 'visited_item';
          resource = gon.item_id;
          resourceType = 'item';
          inContext['course_id'] = gon.course_id;
          inContext['section_id'] = gon.section_id;
        } else if (
          /\/pinboard$/.test(path) &&
          !/\/statistics\/pinboard/.test(path)
        ) {
          verb = 'visited_pinboard';
          resource = gon.course_id;
          resourceType = 'course';
        } else if (/\/progress$/.test(path)) {
          verb = 'visited_progress';
          resource = gon.course_id;
          resourceType = 'course';
        } else if (/\/learning_rooms$/.test(path)) {
          verb = 'visited_learning_rooms';
          resource = gon.course_id;
          resourceType = 'course';
        } else if (/\/announcements$/.test(path)) {
          verb = 'visited_announcements';
          resource = gon.course_id;
          resourceType = 'course';
        } else if (/^\/learn$/.test(path)) {
          resource = gon.course_id;
          if (resource) {
            verb = 'visited_recap';
            resourceType = 'course';
          }
        } else if (/^\/dashboard\/profile$/.test(path)) {
          verb = 'visited_profile';
          resource = gon.user_id;
          resourceType = 'user';
        } else if (/^\/dashboard\/documents$/.test(path)) {
          verb = 'visited_documents';
          resource = gon.user_id;
          resourceType = 'user';
        } else if (/^\/dashboard\/activity$/.test(path)) {
          verb = 'visited_activity';
          resource = gon.user_id;
          resourceType = 'user';
        } else if (/^\/dashboard$/.test(path)) {
          verb = 'visited_dashboard';
          resource = gon.user_id;
          resourceType = 'user';
        } else if (/^\/preferences$/.test(path)) {
          verb = 'visited_preferences';
          resource = gon.user_id;
          resourceType = 'user';
        }
      }
      if (verb) {
        return $(document).trigger('track-event', {
          verb: verb,
          resource: resource,
          resourceType: resourceType,
          inContext: inContext,
        });
      }
    }
  };
}).call(this);
