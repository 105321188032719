// Generated by CoffeeScript 2.7.0
/* eslint-disable */

// Store array with mappings from numerical to hex representation
var _hexMap = [];
for (var i = 0; i < 256; i++) {
  _hexMap[i] = (i + 0x100).toString(16).substr(1);
}

/*
 * Generates a random UUID (version 4).
 */
function generateUUID() {
  var crypto = window.crypto || window.msCrypto;

  if (crypto && crypto.getRandomValues) {
    // crypto-based RNG with high quality of randomness
    var rand = new Uint8Array(16);

    crypto.getRandomValues(rand);

    rand[6] = (rand[6] & 0x0f) | 0x40;
    rand[8] = (rand[8] & 0x3f) | 0x80;

    return (
      _hexMap[rand[0]] +
      _hexMap[rand[1]] +
      _hexMap[rand[2]] +
      _hexMap[rand[3]] +
      '-' +
      _hexMap[rand[4]] +
      _hexMap[rand[5]] +
      '-' +
      _hexMap[rand[6]] +
      _hexMap[rand[7]] +
      '-' +
      _hexMap[rand[8]] +
      _hexMap[rand[9]] +
      '-' +
      _hexMap[rand[10]] +
      _hexMap[rand[11]] +
      _hexMap[rand[12]] +
      _hexMap[rand[13]] +
      _hexMap[rand[14]] +
      _hexMap[rand[15]]
    );
  } else {
    // fallback to Math.random() with unspecified quality of randomness
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }
}

function getClientID() {
  if (typeof Storage !== 'undefined') {
    if (localStorage.getItem('client_id') == null) {
      localStorage.setItem('client_id', generateUUID());
    }
    return localStorage.getItem('client_id');
  } else {
    return null;
  }
}

(function () {
  // This plugin was created for the xikolo-web application
  // in order to track JS events.
  var ref,
    boundMethodCheck = function (instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new Error('Bound instance method accessed before binding');
      }
    };

  ref =
    window.Lanalytics.Plugins.JSEventsPlugin = class JSEventsPlugin extends (
      Lanalytics.Plugin
    ) {
      // This is the interface method expected by Lanalytics
      static newInstance(lanalytics) {
        return new Lanalytics.Plugins.JSEventsPlugin(lanalytics);
      }

      constructor(lanalytics) {
        super(lanalytics);
        // It is important to use '=>' because then 'this' will be bound to the
        // StaticHtmlEventTracker instance (in order to access lanalytics)
        this.trackEvent = this.trackEvent.bind(this);
        this._init();
      }

      _init() {
        return $(document).on('track-event', this.trackEvent);
      }

      _createResourceFrom(data) {
        return new Lanalytics.Model.StmtResource(
          data.resourceType,
          data.resource,
        );
      }

      _findResource(e) {
        var resource;
        // Try to find the closest resource traveling up the DOM
        resource = $(e.target.activeElement).closest(
          '[data-lanalytics-resource]',
        );
        if (resource.length > 0) {
          return resource.data('lanalytics-resource');
        }
        // Try to find any resource
        resource = $('[data-lanalytics-resource]');
        if (resource.length === 0) {
          return null;
        }
        return resource.first().data('lanalytics-resource');
      }

      _getResource(e, data) {
        var resource, stmtResource;
        if (!data.resourceType || !data.resource) {
          resource = this._findResource(e);
          data.resourceType = resource.type;
          data.resource = resource.uuid;
        }
        stmtResource = this._createResourceFrom(data);
        return stmtResource;
      }

      _getContext(data) {
        var client_id,
          context,
          context_cookie,
          context_data,
          inContext,
          key,
          ref1,
          value;
        inContext = {};
        // Add all context that exists on the site
        context = $('[data-lanalytics-context]');
        if (context.length !== 0) {
          context.each(function (i, ctx) {
            var attrs, key, results, value;
            attrs = $(ctx).data('lanalytics-context');
            results = [];
            for (key in attrs) {
              value = attrs[key];
              results.push((inContext[key] = value));
            }
            return results;
          });
        }
        context_cookie = $.cookie('lanalytics-context');
        if (gon.in_app && context_cookie != null) {
          context_data = JSON.parse(context_cookie);
          for (key in context_data) {
            value = context_data[key];
            inContext[key] = value;
          }
        } else {
          inContext['user_agent'] =
            typeof navigator !== 'undefined' && navigator !== null
              ? navigator.userAgent
              : void 0;
          inContext['screen_width'] =
            typeof screen !== 'undefined' && screen !== null
              ? screen.width
              : void 0;
          inContext['screen_height'] =
            typeof screen !== 'undefined' && screen !== null
              ? screen.height
              : void 0;
          if (gon.build_version != null) {
            inContext['build_version'] = gon.build_version;
          }
          client_id = getClientID();
          if (client_id != null) {
            inContext['client_id'] = client_id;
          }
        }
        ref1 = data.inContext;
        for (key in ref1) {
          value = ref1[key];
          // Override all context specified in individual elements
          inContext[key] = value;
        }
        return inContext;
      }

      trackEvent(e, data) {
        var inContext, stmtResource;
        boundMethodCheck(this, ref);
        inContext = this._getContext(data);
        stmtResource = this._getResource(e, data);
        if (
          (typeof gon !== 'undefined' && gon !== null
            ? gon.course_id
            : void 0) != null &&
          stmtResource.uuid !== gon.course_id
        ) {
          inContext['course_id'] = gon.course_id;
        }
        if (
          (typeof gon !== 'undefined' && gon !== null
            ? gon.section_id
            : void 0) != null &&
          stmtResource.uuid !== gon.section_id
        ) {
          inContext['section_id'] = gon.section_id;
        }
        return this.lanalytics.trackCurrentUserDoing(
          data.verb,
          stmtResource,
          data.withResult,
          inContext,
        );
      }
    };
}).call(this);
