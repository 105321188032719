// Generated by CoffeeScript 2.7.0
/* eslint-disable */

(function () {
  var base;

  window.Lanalytics || (window.Lanalytics = {});

  (base = window.Lanalytics).Model || (base.Model = {});

  window.Lanalytics.Model.StmtResource = class StmtResource extends (
    Lanalytics.Model.StmtComponent
  ) {
    constructor(type, uuid) {
      super(type);
      if (uuid == null || !uuid.trim().length) {
        throw "'uuid' argument cannot be nil or empty";
      }
      this.uuid = uuid.toString();
    }

    params() {
      return {
        type: this.type,
        uuid: this.uuid,
      };
    }
  };
}).call(this);
