/* eslint-disable */
import ready from 'util/ready';

// Generated by CoffeeScript 2.7.0
(function () {
  var DEBUG, base, lanalytics;

  if (typeof DEBUG === 'undefined') {
    DEBUG = false;
  }

  window.Lanalytics || (window.Lanalytics = {});

  window.Lanalytics.Framework = class Framework {
    constructor() {
      this.eventQueue = [];
      this.plugins = [];
    }

    addPlugin(plugin) {
      return this.plugins.push(plugin);
    }

    currentUser() {
      if (
        (typeof gon !== 'undefined' && gon !== null ? gon.user_id : void 0) !=
        null
      ) {
        return new Lanalytics.Model.StmtUser(gon.user_id);
      } else {
        return new Lanalytics.Model.AnonymousUser();
      }
    }

    track(user, verb, resource, withResult = {}, inContext = {}) {
      var experienceStmt, now;
      verb = new Lanalytics.Model.StmtVerb(verb);
      now = new Date();
      experienceStmt = new Lanalytics.Model.ExpApiStatement(
        user,
        verb,
        resource,
        now,
        withResult,
        inContext,
      );
      this.eventQueue.push(experienceStmt);
      this.processEventQueue();
      if (DEBUG) {
        return console.debug('New event was tracked:', experienceStmt);
      }
    }

    // Resource should be an object that contains the resource type and the resource key
    trackCurrentUserDoing(verb, resource, withResult = {}, inContext = {}) {
      var user;
      user = this.currentUser();
      return this.track(user, verb, resource, withResult, inContext);
    }

    processEventQueue() {
      var errorHandler, experienceStatement, request;
      experienceStatement = this.eventQueue.shift();
      request = new XMLHttpRequest();
      request.open('POST', '/api/v2/tracking-events', true);
      request.setRequestHeader('Content-Type', 'application/json');
      errorHandler = () => {
        this.eventQueue.push(experienceStatement);
      };
      request.onerror = errorHandler;
      request.onload = function () {
        if (request.status < 200 || request.status >= 400) {
          errorHandler();
        }
      };
      return request.send(experienceStatement.serializeJsonApi());
    }
  };

  window.Lanalytics.Plugin = class Plugin {
    constructor(lanalytics1) {
      this.lanalytics = lanalytics1;
    }

    static newInstance(lanalyticsTracker) {
      throw 'This function has to be implemented in the subclass.';
    }
  };

  // The plugin are supposed to be defined under this namesspace
  (base = window.Lanalytics).Plugins || (base.Plugins = {});

  lanalytics = new window.Lanalytics.Framework();

  ready(function () {
    var error, i, len, plugin, pluginClassName, ref, results;
    ref = Object.keys(Lanalytics.Plugins);
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      pluginClassName = ref[i];
      try {
        plugin = Lanalytics.Plugins[pluginClassName].newInstance(lanalytics);
        lanalytics.addPlugin(plugin);
        if (DEBUG) {
          results.push(
            console.log(
              `Lanalytics.Plugins.${pluginClassName} found, created and added`,
            ),
          );
        } else {
          results.push(void 0);
        }
      } catch (error1) {
        error = error1;
        if (DEBUG) {
          console.log(
            `Lanalytics.Plugins.${pluginClassName} found, but could not be instantiated`,
          );
        }
        if (DEBUG) {
          results.push(console.log(error.stack));
        } else {
          results.push(void 0);
        }
      }
    }
    return results;
  });
}).call(this);
